:root {
    --blue: #3369d6;
    --indigo: #5603ad;
    --purple: #801096;
    --pink: #e36397;
    --red: #f03;
    --orange: #ff3b00;
    --yellow: #ffd600;
    --green: #00c759;
    --teal: #73e9ef;
    --cyan: #2bffc6;
    --white: #fff;
    --gray: #6c757d;
    --gray-dark: #343a40;
    --light: #dee2e6;
    --lighter: #f8f9fa;
    --primary: #288cff;
    --secondary: #e8f2ff;
    --success: #00c759;
    --info: #73e9ef;
    --warning: #ff3b00;
    --danger: #f03;
    --light: #e9ecef;
    --dark: #343a40;
    --tertiary: #192b3f;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace
}

*,
::after,
::before {
    box-sizing: border-box
}

html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent
}

aside,
footer,
header,
main,
nav,
section {
    display: block
}

body {
    margin: 0;
    font-family: Nunito, sans-serif;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.6;
    color: #495057;
    text-align: left;
    background-color: #fff
}

[tabindex="-1"]:focus {
    outline: 0 !important
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: .5rem
}

p {
    margin-top: 0;
    margin-bottom: 1rem
}

ul {
    margin-top: 0;
    margin-bottom: 1rem
}

ul ul {
    margin-bottom: 0
}

b,
strong {
    font-weight: bolder
}

a {
    color: #288cff;
    text-decoration: none;
    background-color: transparent;
    -webkit-text-decoration-skip: objects
}

a:hover {
    color: #0066db;
    -webkit-text-decoration: unone;
            text-decoration: unone
}

a:not([href]):not([tabindex]) {
    color: inherit;
    text-decoration: none
}

a:not([href]):not([tabindex]):focus,
a:not([href]):not([tabindex]):hover {
    color: inherit;
    text-decoration: none
}

a:not([href]):not([tabindex]):focus {
    outline: 0
}

code {
    font-family: monospace, monospace;
    font-size: 1em
}

img {
    vertical-align: middle;
    border-style: none
}

svg:not(:root) {
    overflow: hidden
}

label {
    display: inline-block;
    margin-bottom: .5rem
}

button {
    border-radius: 0
}

button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color
}

button {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit
}

button {
    overflow: visible
}

button {
    text-transform: none
}

[type=reset],
[type=submit],
button,
html [type=button] {
    -webkit-appearance: button
}

[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner,
button::-moz-focus-inner {
    padding: 0;
    border-style: none
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
    height: auto
}

[type=search] {
    outline-offset: -2px;
    -webkit-appearance: none
}

[type=search]::-webkit-search-cancel-button,
[type=search]::-webkit-search-decoration {
    -webkit-appearance: none
}

::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button
}

output {
    display: inline-block
}

template {
    display: none
}

[hidden] {
    display: none !important
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-bottom: .5rem;
    font-family: inherit;
    font-weight: 600;
    line-height: 1.3;
    color: #343a40
}

.h1,
h1 {
    font-size: 2.1875rem
}

.h2,
h2 {
    font-size: 1.75rem
}

.h3,
h3 {
    font-size: 1.53125rem
}

.h4,
h4 {
    font-size: 1.3125rem
}

.h5,
h5 {
    font-size: 1.09375rem
}

.h6,
h6 {
    font-size: .875rem
}

.lead {
    font-size: 1.09375rem;
    font-weight: 300
}

.display-1 {
    font-size: 6rem;
    font-weight: 300;
    line-height: 1.3
}

.display-2 {
    font-size: 5.5rem;
    font-weight: 300;
    line-height: 1.3
}

.display-3 {
    font-size: 4.5rem;
    font-weight: 300;
    line-height: 1.3
}

.display-4 {
    font-size: 3.5rem;
    font-weight: 300;
    line-height: 1.3
}

.list-unstyled {
    padding-left: 0;
    list-style: none
}

.list-inline {
    padding-left: 0;
    list-style: none
}

.list-inline-item {
    display: inline-block
}

.list-inline-item:not(:last-child) {
    margin-right: .5rem
}

.img-fluid {
    max-width: 100%;
    height: auto
}

code {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace
}

code {
    font-size: 87.5%;
    color: #e36397;
    word-break: break-word
}

a>code {
    color: inherit
}

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto
}

@media (min-width:576px) {
    .container {
        max-width: 540px
    }
}

@media (min-width:768px) {
    .container {
        max-width: 720px
    }
}

@media (min-width:992px) {
    .container {
        max-width: 960px
    }
}

@media (min-width:1200px) {
    .container {
        max-width: 1140px
    }
}

.container-fluid {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px
}

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px
}

.col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%
}

.col-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none
}

.col-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%
}

.col-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%
}

.col-3 {
    flex: 0 0 25%;
    max-width: 25%
}

.col-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%
}

.col-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%
}

.col-6 {
    flex: 0 0 50%;
    max-width: 50%
}

.col-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%
}

.col-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%
}

.col-9 {
    flex: 0 0 75%;
    max-width: 75%
}

.col-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%
}

.col-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%
}

.col-12 {
    flex: 0 0 100%;
    max-width: 100%
}

.offset-1 {
    margin-left: 8.3333333333%
}

.offset-2 {
    margin-left: 16.6666666667%
}

.offset-3 {
    margin-left: 25%
}

.offset-4 {
    margin-left: 33.3333333333%
}

.offset-5 {
    margin-left: 41.6666666667%
}

.offset-6 {
    margin-left: 50%
}

.offset-7 {
    margin-left: 58.3333333333%
}

.offset-8 {
    margin-left: 66.6666666667%
}

.offset-9 {
    margin-left: 75%
}

.offset-10 {
    margin-left: 83.3333333333%
}

.offset-11 {
    margin-left: 91.6666666667%
}

@media (min-width:576px) {
    .col-sm {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%
    }

    .col-sm-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: none
    }

    .col-sm-1 {
        flex: 0 0 8.3333333333%;
        max-width: 8.3333333333%
    }

    .col-sm-2 {
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%
    }

    .col-sm-3 {
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-sm-4 {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%
    }

    .col-sm-5 {
        flex: 0 0 41.6666666667%;
        max-width: 41.6666666667%
    }

    .col-sm-6 {
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-sm-7 {
        flex: 0 0 58.3333333333%;
        max-width: 58.3333333333%
    }

    .col-sm-8 {
        flex: 0 0 66.6666666667%;
        max-width: 66.6666666667%
    }

    .col-sm-9 {
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-sm-10 {
        flex: 0 0 83.3333333333%;
        max-width: 83.3333333333%
    }

    .col-sm-11 {
        flex: 0 0 91.6666666667%;
        max-width: 91.6666666667%
    }

    .col-sm-12 {
        flex: 0 0 100%;
        max-width: 100%
    }

    .offset-sm-0 {
        margin-left: 0
    }

    .offset-sm-1 {
        margin-left: 8.3333333333%
    }

    .offset-sm-2 {
        margin-left: 16.6666666667%
    }

    .offset-sm-3 {
        margin-left: 25%
    }

    .offset-sm-4 {
        margin-left: 33.3333333333%
    }

    .offset-sm-5 {
        margin-left: 41.6666666667%
    }

    .offset-sm-6 {
        margin-left: 50%
    }

    .offset-sm-7 {
        margin-left: 58.3333333333%
    }

    .offset-sm-8 {
        margin-left: 66.6666666667%
    }

    .offset-sm-9 {
        margin-left: 75%
    }

    .offset-sm-10 {
        margin-left: 83.3333333333%
    }

    .offset-sm-11 {
        margin-left: 91.6666666667%
    }
}

@media (min-width:768px) {
    .col-md {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%
    }

    .col-md-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: none
    }

    .col-md-1 {
        flex: 0 0 8.3333333333%;
        max-width: 8.3333333333%
    }

    .col-md-2 {
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%
    }

    .col-md-3 {
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-md-4 {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%
    }

    .col-md-5 {
        flex: 0 0 41.6666666667%;
        max-width: 41.6666666667%
    }

    .col-md-6 {
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-md-7 {
        flex: 0 0 58.3333333333%;
        max-width: 58.3333333333%
    }

    .col-md-8 {
        flex: 0 0 66.6666666667%;
        max-width: 66.6666666667%
    }

    .col-md-9 {
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-md-10 {
        flex: 0 0 83.3333333333%;
        max-width: 83.3333333333%
    }

    .col-md-11 {
        flex: 0 0 91.6666666667%;
        max-width: 91.6666666667%
    }

    .col-md-12 {
        flex: 0 0 100%;
        max-width: 100%
    }

    .offset-md-0 {
        margin-left: 0
    }

    .offset-md-1 {
        margin-left: 8.3333333333%
    }

    .offset-md-2 {
        margin-left: 16.6666666667%
    }

    .offset-md-3 {
        margin-left: 25%
    }

    .offset-md-4 {
        margin-left: 33.3333333333%
    }

    .offset-md-5 {
        margin-left: 41.6666666667%
    }

    .offset-md-6 {
        margin-left: 50%
    }

    .offset-md-7 {
        margin-left: 58.3333333333%
    }

    .offset-md-8 {
        margin-left: 66.6666666667%
    }

    .offset-md-9 {
        margin-left: 75%
    }

    .offset-md-10 {
        margin-left: 83.3333333333%
    }

    .offset-md-11 {
        margin-left: 91.6666666667%
    }
}

@media (min-width:992px) {
    .col-lg {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%
    }

    .col-lg-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: none
    }

    .col-lg-1 {
        flex: 0 0 8.3333333333%;
        max-width: 8.3333333333%
    }

    .col-lg-2 {
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%
    }

    .col-lg-3 {
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-lg-4 {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%
    }

    .col-lg-5 {
        flex: 0 0 41.6666666667%;
        max-width: 41.6666666667%
    }

    .col-lg-6 {
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-lg-7 {
        flex: 0 0 58.3333333333%;
        max-width: 58.3333333333%
    }

    .col-lg-8 {
        flex: 0 0 66.6666666667%;
        max-width: 66.6666666667%
    }

    .col-lg-9 {
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-lg-10 {
        flex: 0 0 83.3333333333%;
        max-width: 83.3333333333%
    }

    .col-lg-11 {
        flex: 0 0 91.6666666667%;
        max-width: 91.6666666667%
    }

    .col-lg-12 {
        flex: 0 0 100%;
        max-width: 100%
    }

    .offset-lg-0 {
        margin-left: 0
    }

    .offset-lg-1 {
        margin-left: 8.3333333333%
    }

    .offset-lg-2 {
        margin-left: 16.6666666667%
    }

    .offset-lg-3 {
        margin-left: 25%
    }

    .offset-lg-4 {
        margin-left: 33.3333333333%
    }

    .offset-lg-5 {
        margin-left: 41.6666666667%
    }

    .offset-lg-6 {
        margin-left: 50%
    }

    .offset-lg-7 {
        margin-left: 58.3333333333%
    }

    .offset-lg-8 {
        margin-left: 66.6666666667%
    }

    .offset-lg-9 {
        margin-left: 75%
    }

    .offset-lg-10 {
        margin-left: 83.3333333333%
    }

    .offset-lg-11 {
        margin-left: 91.6666666667%
    }
}

.btn {
    display: inline-block;
    font-weight: 600;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
            user-select: none;
    border: .0625rem solid transparent;
    padding: .75rem 1.5rem;
    font-size: .875rem;
    line-height: 1.6;
    border-radius: .375rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.btn:focus,
.btn:hover {
    text-decoration: none
}

.btn:focus {
    outline: 0;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, .04)
}

.btn:disabled {
    opacity: .65;
    box-shadow: none
}

.btn:not(:disabled):not(.disabled) {
    cursor: pointer
}

.btn:not(:disabled):not(.disabled).active,
.btn:not(:disabled):not(.disabled):active {
    background-image: none;
    box-shadow: none
}

.btn:not(:disabled):not(.disabled).active:focus,
.btn:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, .04), none
}

.btn-primary {
    color: #fff;
    background: #288cff linear-gradient(180deg, #489dff, #288cff) repeat-x;
    border-color: #288cff;
    box-shadow: none
}

.btn-primary:hover {
    color: #fff;
    background: #0278ff linear-gradient(180deg, #288cff, #0278ff) repeat-x;
    border-color: #0071f4
}

.btn-primary:focus {
    box-shadow: none, 0 0 0 0 rgba(40, 140, 255, .5)
}

.btn-primary:disabled {
    color: #fff;
    background-color: #288cff;
    border-color: #288cff
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0071f4;
    background-image: none;
    border-color: #006ce7
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: none, 0 0 0 0 rgba(40, 140, 255, .5)
}

.btn-light {
    color: #212529;
    background: #e9ecef linear-gradient(180deg, #eceff1, #e9ecef) repeat-x;
    border-color: #e9ecef;
    box-shadow: none
}

.btn-light:hover {
    color: #212529;
    background: #d3d9df linear-gradient(180deg, #dadfe4, #d3d9df) repeat-x;
    border-color: #cbd3da
}

.btn-light:focus {
    box-shadow: none, 0 0 0 0 rgba(233, 236, 239, .5)
}

.btn-light:disabled {
    color: #212529;
    background-color: #e9ecef;
    border-color: #e9ecef
}

.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active,
.show>.btn-light.dropdown-toggle {
    color: #212529;
    background-color: #cbd3da;
    background-image: none;
    border-color: #c4ccd4
}

.btn-light:not(:disabled):not(.disabled).active:focus,
.btn-light:not(:disabled):not(.disabled):active:focus,
.show>.btn-light.dropdown-toggle:focus {
    box-shadow: none, 0 0 0 0 rgba(233, 236, 239, .5)
}

.btn-dark {
    color: #fff;
    background: #343a40 linear-gradient(180deg, #52585d, #343a40) repeat-x;
    border-color: #343a40;
    box-shadow: none
}

.btn-dark:hover {
    color: #fff;
    background: #23272b linear-gradient(180deg, #44474b, #23272b) repeat-x;
    border-color: #1d2124
}

.btn-dark:focus {
    box-shadow: none, 0 0 0 0 rgba(52, 58, 64, .5)
}

.btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40
}

.btn-dark:not(:disabled):not(.disabled).active,
.btn-dark:not(:disabled):not(.disabled):active,
.show>.btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    background-image: none;
    border-color: #171a1d
}

.btn-dark:not(:disabled):not(.disabled).active:focus,
.btn-dark:not(:disabled):not(.disabled):active:focus,
.show>.btn-dark.dropdown-toggle:focus {
    box-shadow: none, 0 0 0 0 rgba(52, 58, 64, .5)
}

.btn-link {
    font-weight: 400;
    color: #288cff;
    background-color: transparent
}

.btn-link:hover {
    color: #0066db;
    -webkit-text-decoration: unone;
            text-decoration: unone;
    background-color: transparent;
    border-color: transparent
}

.btn-link:focus {
    -webkit-text-decoration: unone;
            text-decoration: unone;
    border-color: transparent;
    box-shadow: none
}

.btn-link:disabled {
    color: #6c757d
}

.btn-lg {
    padding: .95rem 1rem;
    font-size: 1.09375rem;
    line-height: 1.5;
    border-radius: .375rem
}

.btn-sm {
    padding: .55rem 1rem;
    font-size: .765625rem;
    line-height: 1.5;
    border-radius: .375rem
}

.btn-block {
    display: block;
    width: 100%
}

.btn-block+.btn-block {
    margin-top: .5rem
}

.fade {
    opacity: 0;
    transition: opacity .15s linear
}

.fade.show {
    opacity: 1
}

.collapse {
    display: none
}

.collapse.show {
    display: block
}

tr.collapse.show {
    display: table-row
}

.dropdown {
    position: relative
}

.dropdown-toggle::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent
}

.dropdown-toggle:empty::after {
    margin-left: 0
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: .5rem 0;
    margin: .125rem 0 0;
    font-size: .875rem;
    color: #495057;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 0 solid rgba(0, 0, 0, .15);
    border-radius: .375rem;
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .175)
}

.dropdown-item {
    display: block;
    width: 100%;
    padding: .25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0
}

.dropdown-item:focus,
.dropdown-item:hover {
    color: #16181b;
    text-decoration: none;
    background: #f8f9fa linear-gradient(180deg, #f9fafb, #f8f9fa) repeat-x
}

.dropdown-item.active,
.dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background: #288cff linear-gradient(180deg, #489dff, #288cff) repeat-x
}

.dropdown-item:disabled {
    color: #6c757d;
    background-color: transparent;
    background-image: none
}

.dropdown-menu.show {
    display: block
}

.dropdown-header {
    display: block;
    padding: .5rem 1.5rem;
    margin-bottom: 0;
    font-size: .765625rem;
    color: #6c757d;
    white-space: nowrap
}

.nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
}

.nav-link {
    display: block;
    padding: .5rem 1rem
}

.nav-link:focus,
.nav-link:hover {
    text-decoration: none
}

.navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: .5rem 1rem
}

.navbar>.container,
.navbar>.container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between
}

.navbar-brand {
    display: inline-block;
    padding-top: .325rem;
    padding-bottom: .325rem;
    margin-right: 1rem;
    font-size: 1.09375rem;
    line-height: inherit;
    white-space: nowrap
}

.navbar-brand:focus,
.navbar-brand:hover {
    text-decoration: none
}

.navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
}

.navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0
}

.navbar-nav .dropdown-menu {
    position: static;
    float: none
}

.navbar-text {
    display: inline-block;
    padding-top: .5rem;
    padding-bottom: .5rem
}

.navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center
}

.navbar-toggler {
    padding: .25rem .75rem;
    font-size: 1.09375rem;
    line-height: 1;
    background-color: transparent;
    border: .0625rem solid transparent;
    border-radius: .375rem
}

.navbar-toggler:focus,
.navbar-toggler:hover {
    text-decoration: none
}

.navbar-toggler:not(:disabled):not(.disabled) {
    cursor: pointer
}

.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    background: no-repeat center center;
    background-size: 100% 100%
}

@media (max-width:575.98px) {

    .navbar-expand-sm>.container,
    .navbar-expand-sm>.container-fluid {
        padding-right: 0;
        padding-left: 0
    }
}

@media (min-width:576px) {
    .navbar-expand-sm {
        flex-flow: row nowrap;
        justify-content: flex-start
    }

    .navbar-expand-sm .navbar-nav {
        flex-direction: row
    }

    .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-sm .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto
    }

    .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 1rem;
        padding-left: 1rem
    }

    .navbar-expand-sm>.container,
    .navbar-expand-sm>.container-fluid {
        flex-wrap: nowrap
    }

    .navbar-expand-sm .navbar-collapse {
        display: flex !important;
        flex-basis: auto
    }

    .navbar-expand-sm .navbar-toggler {
        display: none
    }
}

@media (max-width:767.98px) {

    .navbar-expand-md>.container,
    .navbar-expand-md>.container-fluid {
        padding-right: 0;
        padding-left: 0
    }
}

@media (min-width:768px) {
    .navbar-expand-md {
        flex-flow: row nowrap;
        justify-content: flex-start
    }

    .navbar-expand-md .navbar-nav {
        flex-direction: row
    }

    .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-md .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto
    }

    .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 1rem;
        padding-left: 1rem
    }

    .navbar-expand-md>.container,
    .navbar-expand-md>.container-fluid {
        flex-wrap: nowrap
    }

    .navbar-expand-md .navbar-collapse {
        display: flex !important;
        flex-basis: auto
    }

    .navbar-expand-md .navbar-toggler {
        display: none
    }
}

@media (max-width:991.98px) {

    .navbar-expand-lg>.container,
    .navbar-expand-lg>.container-fluid {
        padding-right: 0;
        padding-left: 0
    }
}

@media (min-width:992px) {
    .navbar-expand-lg {
        flex-flow: row nowrap;
        justify-content: flex-start
    }

    .navbar-expand-lg .navbar-nav {
        flex-direction: row
    }

    .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-lg .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto
    }

    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 1rem;
        padding-left: 1rem
    }

    .navbar-expand-lg>.container,
    .navbar-expand-lg>.container-fluid {
        flex-wrap: nowrap
    }

    .navbar-expand-lg .navbar-collapse {
        display: flex !important;
        flex-basis: auto
    }

    .navbar-expand-lg .navbar-toggler {
        display: none
    }
}

.navbar-expand {
    flex-flow: row nowrap;
    justify-content: flex-start
}

.navbar-expand>.container,
.navbar-expand>.container-fluid {
    padding-right: 0;
    padding-left: 0
}

.navbar-expand .navbar-nav {
    flex-direction: row
}

.navbar-expand .navbar-nav .dropdown-menu {
    position: absolute
}

.navbar-expand .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto
}

.navbar-expand .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem
}

.navbar-expand>.container,
.navbar-expand>.container-fluid {
    flex-wrap: nowrap
}

.navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto
}

.navbar-expand .navbar-toggler {
    display: none
}

.navbar-light .navbar-brand {
    color: rgba(0, 0, 0, .9)
}

.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover {
    color: rgba(0, 0, 0, .9)
}

.navbar-light .navbar-nav .nav-link {
    color: rgba(0, 0, 0, .5)
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
    color: rgba(0, 0, 0, .7)
}

.navbar-light .navbar-nav .active>.nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show>.nav-link {
    color: rgba(0, 0, 0, .9)
}

.navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, .5);
    border-color: rgba(0, 0, 0, .1)
}

.navbar-light .navbar-toggler-icon {
    background-image: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0,0,0,.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"), "#", "%23")
}

.navbar-light .navbar-text {
    color: rgba(0, 0, 0, .5)
}

.navbar-light .navbar-text a {
    color: rgba(0, 0, 0, .9)
}

.navbar-light .navbar-text a:focus,
.navbar-light .navbar-text a:hover {
    color: rgba(0, 0, 0, .9)
}

.navbar-dark .navbar-brand {
    color: #fff
}

.navbar-dark .navbar-brand:focus,
.navbar-dark .navbar-brand:hover {
    color: #fff
}

.navbar-dark .navbar-nav .nav-link {
    color: rgba(255, 255, 255, .5)
}

.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
    color: rgba(255, 255, 255, .75)
}

.navbar-dark .navbar-nav .active>.nav-link,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .show>.nav-link {
    color: #fff
}

.navbar-dark .navbar-toggler {
    color: rgba(255, 255, 255, .5);
    border-color: transparent
}

.navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255,0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E")
}

.navbar-dark .navbar-text {
    color: rgba(255, 255, 255, .5)
}

.navbar-dark .navbar-text a {
    color: #fff
}

.navbar-dark .navbar-text a:focus,
.navbar-dark .navbar-text a:hover {
    color: #fff
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: .0625rem solid rgba(0, 0, 0, .05);
    border-radius: .375rem
}

.card-body {
    flex: 1 1 auto;
    padding: 1.5rem
}

.card-title {
    margin-bottom: 1.25rem
}

.card-text:last-child {
    margin-bottom: 0
}

.card-link:hover {
    text-decoration: none
}

.card-link+.card-link {
    margin-left: 1.5rem
}

.card-header {
    padding: 1.25rem 1.5rem;
    margin-bottom: 0;
    background-color: #fff;
    border-bottom: .0625rem solid rgba(0, 0, 0, .05)
}

.card-header:first-child {
    border-radius: calc(.375rem - .0625rem) calc(.375rem - .0625rem) 0 0
}

.card-footer {
    padding: 1.25rem 1.5rem;
    background-color: #fff;
    border-top: .0625rem solid rgba(0, 0, 0, .05)
}

.card-footer:last-child {
    border-radius: 0 0 calc(.375rem - .0625rem) calc(.375rem - .0625rem)
}

.card-img {
    width: 100%;
    border-radius: calc(.375rem - .0625rem)
}

.card-img-top {
    width: 100%;
    border-top-left-radius: calc(.375rem - .0625rem);
    border-top-right-radius: calc(.375rem - .0625rem)
}

.card-img-bottom {
    width: 100%;
    border-bottom-right-radius: calc(.375rem - .0625rem);
    border-bottom-left-radius: calc(.375rem - .0625rem)
}

.card-deck {
    display: flex;
    flex-direction: column
}

.card-deck .card {
    margin-bottom: 15px
}

@media (min-width:576px) {
    .card-deck {
        flex-flow: row wrap;
        margin-right: -15px;
        margin-left: -15px
    }

    .card-deck .card {
        display: flex;
        flex: 1 0;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px
    }
}

.page-link {
    position: relative;
    display: block;
    padding: .5rem .75rem;
    margin-left: -.0625rem;
    line-height: 1.25;
    color: #6c757d;
    background-color: #fff;
    border: .0625rem solid #dee2e6
}

.page-link:hover {
    color: #6c757d;
    text-decoration: none;
    background-color: #dee2e6;
    border-color: #dee2e6
}

.page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, .04)
}

.page-link:not(:disabled):not(.disabled) {
    cursor: pointer
}

.page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: .375rem;
    border-bottom-left-radius: .375rem
}

.page-item:last-child .page-link {
    border-top-right-radius: .375rem;
    border-bottom-right-radius: .375rem
}

.page-item.active .page-link {
    z-index: 1;
    color: #fff;
    background-color: #288cff;
    border-color: #288cff
}

@-webkit-keyframes progress-bar-stripes {
    from {
        background-position: 1rem 0
    }

    to {
        background-position: 0 0
    }
}

@keyframes progress-bar-stripes {
    from {
        background-position: 1rem 0
    }

    to {
        background-position: 0 0
    }
}

.media {
    display: flex;
    align-items: flex-start
}

.media-body {
    flex: 1 1
}

.close {
    float: right;
    font-size: 1.3125rem;
    font-weight: 600;
    line-height: 1;
    color: rgba(255, 255, 255, .6);
    text-shadow: none;
    opacity: .5
}

.close:focus,
.close:hover {
    color: rgba(255, 255, 255, .6);
    text-decoration: none;
    opacity: .75
}

.close:not(:disabled):not(.disabled) {
    cursor: pointer
}

button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none
}

@supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {

    .carousel-item-next.carousel-item-left,
    .carousel-item-prev.carousel-item-right {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0)
    }
}

@supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {

    .active.carousel-item-right,
    .carousel-item-next {
        -webkit-transform: translate3d(100%, 0, 0);
                transform: translate3d(100%, 0, 0)
    }
}

@supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {

    .active.carousel-item-left,
    .carousel-item-prev {
        -webkit-transform: translate3d(-100%, 0, 0);
                transform: translate3d(-100%, 0, 0)
    }
}

.align-top {
    vertical-align: top !important
}

.align-bottom {
    vertical-align: bottom !important
}

.align-text-bottom {
    vertical-align: text-bottom !important
}

.align-text-top {
    vertical-align: text-top !important
}

.bg-primary {
    background-color: #288cff !important
}

a.bg-primary:focus,
a.bg-primary:hover,
button.bg-primary:focus,
button.bg-primary:hover {
    background-color: #0071f4 !important
}

.bg-light {
    background-color: #e9ecef !important
}

a.bg-light:focus,
a.bg-light:hover,
button.bg-light:focus,
button.bg-light:hover {
    background-color: #cbd3da !important
}

.bg-dark {
    background-color: #343a40 !important
}

a.bg-dark:focus,
a.bg-dark:hover,
button.bg-dark:focus,
button.bg-dark:hover {
    background-color: #1d2124 !important
}

.bg-white {
    background-color: #fff !important
}

.bg-transparent {
    background-color: transparent !important
}

.border {
    border: .0625rem solid #e9ecef !important
}

.border-top {
    border-top: .0625rem solid #e9ecef !important
}

.border-right {
    border-right: .0625rem solid #e9ecef !important
}

.border-bottom {
    border-bottom: .0625rem solid #e9ecef !important
}

.border-left {
    border-left: .0625rem solid #e9ecef !important
}

.border-0 {
    border: 0 !important
}

.border-top-0 {
    border-top: 0 !important
}

.border-right-0 {
    border-right: 0 !important
}

.border-bottom-0 {
    border-bottom: 0 !important
}

.border-left-0 {
    border-left: 0 !important
}

.border-primary {
    border-color: #288cff !important
}

.border-light {
    border-color: #e9ecef !important
}

.border-dark {
    border-color: #343a40 !important
}

.border-white {
    border-color: #fff !important
}

.rounded {
    border-radius: .375rem !important
}

.rounded-top {
    border-top-left-radius: .375rem !important;
    border-top-right-radius: .375rem !important
}

.rounded-right {
    border-top-right-radius: .375rem !important;
    border-bottom-right-radius: .375rem !important
}

.rounded-bottom {
    border-bottom-right-radius: .375rem !important;
    border-bottom-left-radius: .375rem !important
}

.rounded-left {
    border-top-left-radius: .375rem !important;
    border-bottom-left-radius: .375rem !important
}

.rounded-0 {
    border-radius: 0 !important
}

.d-none {
    display: none !important
}

.d-inline {
    display: inline !important
}

.d-inline-block {
    display: inline-block !important
}

.d-block {
    display: block !important
}

.d-flex {
    display: flex !important
}

.d-inline-flex {
    display: inline-flex !important
}

@media (min-width:576px) {
    .d-sm-none {
        display: none !important
    }

    .d-sm-inline {
        display: inline !important
    }

    .d-sm-inline-block {
        display: inline-block !important
    }

    .d-sm-block {
        display: block !important
    }

    .d-sm-flex {
        display: flex !important
    }

    .d-sm-inline-flex {
        display: inline-flex !important
    }
}

@media (min-width:768px) {
    .d-md-none {
        display: none !important
    }

    .d-md-inline {
        display: inline !important
    }

    .d-md-inline-block {
        display: inline-block !important
    }

    .d-md-block {
        display: block !important
    }

    .d-md-flex {
        display: flex !important
    }

    .d-md-inline-flex {
        display: inline-flex !important
    }
}

@media (min-width:992px) {
    .d-lg-none {
        display: none !important
    }

    .d-lg-inline {
        display: inline !important
    }

    .d-lg-inline-block {
        display: inline-block !important
    }

    .d-lg-block {
        display: block !important
    }

    .d-lg-flex {
        display: flex !important
    }

    .d-lg-inline-flex {
        display: inline-flex !important
    }
}

.flex-row {
    flex-direction: row !important
}

.justify-content-start {
    justify-content: flex-start !important
}

.justify-content-end {
    justify-content: flex-end !important
}

.justify-content-center {
    justify-content: center !important
}

.justify-content-around {
    justify-content: space-around !important
}

.align-items-start {
    align-items: flex-start !important
}

.align-items-end {
    align-items: flex-end !important
}

.align-items-center {
    align-items: center !important
}

.align-content-start {
    align-content: flex-start !important
}

.align-content-end {
    align-content: flex-end !important
}

.align-content-center {
    align-content: center !important
}

.align-content-around {
    align-content: space-around !important
}

@media (min-width:576px) {
    .flex-sm-row {
        flex-direction: row !important
    }

    .justify-content-sm-start {
        justify-content: flex-start !important
    }

    .justify-content-sm-end {
        justify-content: flex-end !important
    }

    .justify-content-sm-center {
        justify-content: center !important
    }

    .justify-content-sm-around {
        justify-content: space-around !important
    }

    .align-items-sm-start {
        align-items: flex-start !important
    }

    .align-items-sm-end {
        align-items: flex-end !important
    }

    .align-items-sm-center {
        align-items: center !important
    }

    .align-content-sm-start {
        align-content: flex-start !important
    }

    .align-content-sm-end {
        align-content: flex-end !important
    }

    .align-content-sm-center {
        align-content: center !important
    }

    .align-content-sm-around {
        align-content: space-around !important
    }
}

@media (min-width:768px) {
    .flex-md-row {
        flex-direction: row !important
    }

    .justify-content-md-start {
        justify-content: flex-start !important
    }

    .justify-content-md-end {
        justify-content: flex-end !important
    }

    .justify-content-md-center {
        justify-content: center !important
    }

    .justify-content-md-around {
        justify-content: space-around !important
    }

    .align-items-md-start {
        align-items: flex-start !important
    }

    .align-items-md-end {
        align-items: flex-end !important
    }

    .align-items-md-center {
        align-items: center !important
    }

    .align-content-md-start {
        align-content: flex-start !important
    }

    .align-content-md-end {
        align-content: flex-end !important
    }

    .align-content-md-center {
        align-content: center !important
    }

    .align-content-md-around {
        align-content: space-around !important
    }
}

@media (min-width:992px) {
    .flex-lg-row {
        flex-direction: row !important
    }

    .justify-content-lg-start {
        justify-content: flex-start !important
    }

    .justify-content-lg-end {
        justify-content: flex-end !important
    }

    .justify-content-lg-center {
        justify-content: center !important
    }

    .justify-content-lg-around {
        justify-content: space-around !important
    }

    .align-items-lg-start {
        align-items: flex-start !important
    }

    .align-items-lg-end {
        align-items: flex-end !important
    }

    .align-items-lg-center {
        align-items: center !important
    }

    .align-content-lg-start {
        align-content: flex-start !important
    }

    .align-content-lg-end {
        align-content: flex-end !important
    }

    .align-content-lg-center {
        align-content: center !important
    }

    .align-content-lg-around {
        align-content: space-around !important
    }
}

.float-left {
    float: left !important
}

.float-right {
    float: right !important
}

.float-none {
    float: none !important
}

@media (min-width:576px) {
    .float-sm-left {
        float: left !important
    }

    .float-sm-right {
        float: right !important
    }

    .float-sm-none {
        float: none !important
    }
}

@media (min-width:768px) {
    .float-md-left {
        float: left !important
    }

    .float-md-right {
        float: right !important
    }

    .float-md-none {
        float: none !important
    }
}

@media (min-width:992px) {
    .float-lg-left {
        float: left !important
    }

    .float-lg-right {
        float: right !important
    }

    .float-lg-none {
        float: none !important
    }
}

.position-relative {
    position: relative !important
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
    .sticky-top {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1020
    }
}

.w-25 {
    width: 25% !important
}

.w-50 {
    width: 50% !important
}

.w-75 {
    width: 75% !important
}

.w-100 {
    width: 100% !important
}

.h-25 {
    height: 25% !important
}

.h-50 {
    height: 50% !important
}

.h-75 {
    height: 75% !important
}

.h-100 {
    height: 100% !important
}

.mt-0 {
    margin-top: 0 !important
}

.mb-0 {
    margin-bottom: 0 !important
}

.ml-0 {
    margin-left: 0 !important
}

.mt-1 {
    margin-top: .25rem !important
}

.mb-1 {
    margin-bottom: .25rem !important
}

.ml-1 {
    margin-left: .25rem !important
}

.mt-2 {
    margin-top: .5rem !important
}

.mb-2 {
    margin-bottom: .5rem !important
}

.ml-2 {
    margin-left: .5rem !important
}

.mt-3 {
    margin-top: 1rem !important
}

.mb-3 {
    margin-bottom: 1rem !important
}

.ml-3 {
    margin-left: 1rem !important
}

.mt-4 {
    margin-top: 1.5rem !important
}

.mb-4 {
    margin-bottom: 1.5rem !important
}

.ml-4 {
    margin-left: 1.5rem !important
}

.mt-5 {
    margin-top: 3rem !important
}

.mb-5 {
    margin-bottom: 3rem !important
}

.ml-5 {
    margin-left: 3rem !important
}

.mt-sm {
    margin-top: 2rem !important
}

.mb-sm {
    margin-bottom: 2rem !important
}

.ml-sm {
    margin-left: 2rem !important
}

.mt-md {
    margin-top: 4rem !important
}

.mb-md {
    margin-bottom: 4rem !important
}

.ml-md {
    margin-left: 4rem !important
}

.mt-lg {
    margin-top: 6rem !important
}

.mb-lg {
    margin-bottom: 6rem !important
}

.ml-lg {
    margin-left: 6rem !important
}

.p-0 {
    padding: 0 !important
}

.pt-0,
.py-0 {
    padding-top: 0 !important
}

.pr-0,
.px-0 {
    padding-right: 0 !important
}

.py-0 {
    padding-bottom: 0 !important
}

.px-0 {
    padding-left: 0 !important
}

.p-1 {
    padding: .25rem !important
}

.pt-1,
.py-1 {
    padding-top: .25rem !important
}

.pr-1,
.px-1 {
    padding-right: .25rem !important
}

.py-1 {
    padding-bottom: .25rem !important
}

.px-1 {
    padding-left: .25rem !important
}

.p-2 {
    padding: .5rem !important
}

.pt-2,
.py-2 {
    padding-top: .5rem !important
}

.pr-2,
.px-2 {
    padding-right: .5rem !important
}

.py-2 {
    padding-bottom: .5rem !important
}

.px-2 {
    padding-left: .5rem !important
}

.p-3 {
    padding: 1rem !important
}

.pt-3,
.py-3 {
    padding-top: 1rem !important
}

.pr-3,
.px-3 {
    padding-right: 1rem !important
}

.py-3 {
    padding-bottom: 1rem !important
}

.px-3 {
    padding-left: 1rem !important
}

.p-4 {
    padding: 1.5rem !important
}

.pt-4,
.py-4 {
    padding-top: 1.5rem !important
}

.pr-4,
.px-4 {
    padding-right: 1.5rem !important
}

.py-4 {
    padding-bottom: 1.5rem !important
}

.px-4 {
    padding-left: 1.5rem !important
}

.p-5 {
    padding: 3rem !important
}

.pt-5,
.py-5 {
    padding-top: 3rem !important
}

.pr-5,
.px-5 {
    padding-right: 3rem !important
}

.py-5 {
    padding-bottom: 3rem !important
}

.px-5 {
    padding-left: 3rem !important
}

.p-sm {
    padding: 2rem !important
}

.pt-sm,
.py-sm {
    padding-top: 2rem !important
}

.pr-sm,
.px-sm {
    padding-right: 2rem !important
}

.py-sm {
    padding-bottom: 2rem !important
}

.px-sm {
    padding-left: 2rem !important
}

.p-md {
    padding: 4rem !important
}

.pt-md,
.py-md {
    padding-top: 4rem !important
}

.pr-md,
.px-md {
    padding-right: 4rem !important
}

.py-md {
    padding-bottom: 4rem !important
}

.px-md {
    padding-left: 4rem !important
}

.p-lg {
    padding: 6rem !important
}

.pt-lg,
.py-lg {
    padding-top: 6rem !important
}

.pr-lg,
.px-lg {
    padding-right: 6rem !important
}

.py-lg {
    padding-bottom: 6rem !important
}

.px-lg {
    padding-left: 6rem !important
}

.mt-auto {
    margin-top: auto !important
}

.mb-auto {
    margin-bottom: auto !important
}

.ml-auto {
    margin-left: auto !important
}

@media (min-width:576px) {
    .mt-sm-0 {
        margin-top: 0 !important
    }

    .mb-sm-0 {
        margin-bottom: 0 !important
    }

    .ml-sm-0 {
        margin-left: 0 !important
    }

    .mt-sm-1 {
        margin-top: .25rem !important
    }

    .mb-sm-1 {
        margin-bottom: .25rem !important
    }

    .ml-sm-1 {
        margin-left: .25rem !important
    }

    .mt-sm-2 {
        margin-top: .5rem !important
    }

    .mb-sm-2 {
        margin-bottom: .5rem !important
    }

    .ml-sm-2 {
        margin-left: .5rem !important
    }

    .mt-sm-3 {
        margin-top: 1rem !important
    }

    .mb-sm-3 {
        margin-bottom: 1rem !important
    }

    .ml-sm-3 {
        margin-left: 1rem !important
    }

    .mt-sm-4 {
        margin-top: 1.5rem !important
    }

    .mb-sm-4 {
        margin-bottom: 1.5rem !important
    }

    .ml-sm-4 {
        margin-left: 1.5rem !important
    }

    .mt-sm-5 {
        margin-top: 3rem !important
    }

    .mb-sm-5 {
        margin-bottom: 3rem !important
    }

    .ml-sm-5 {
        margin-left: 3rem !important
    }

    .mt-sm-sm {
        margin-top: 2rem !important
    }

    .mb-sm-sm {
        margin-bottom: 2rem !important
    }

    .ml-sm-sm {
        margin-left: 2rem !important
    }

    .mt-sm-md {
        margin-top: 4rem !important
    }

    .mb-sm-md {
        margin-bottom: 4rem !important
    }

    .ml-sm-md {
        margin-left: 4rem !important
    }

    .mt-sm-lg {
        margin-top: 6rem !important
    }

    .mb-sm-lg {
        margin-bottom: 6rem !important
    }

    .ml-sm-lg {
        margin-left: 6rem !important
    }

    .p-sm-0 {
        padding: 0 !important
    }

    .pt-sm-0,
    .py-sm-0 {
        padding-top: 0 !important
    }

    .pr-sm-0,
    .px-sm-0 {
        padding-right: 0 !important
    }

    .py-sm-0 {
        padding-bottom: 0 !important
    }

    .px-sm-0 {
        padding-left: 0 !important
    }

    .p-sm-1 {
        padding: .25rem !important
    }

    .pt-sm-1,
    .py-sm-1 {
        padding-top: .25rem !important
    }

    .pr-sm-1,
    .px-sm-1 {
        padding-right: .25rem !important
    }

    .py-sm-1 {
        padding-bottom: .25rem !important
    }

    .px-sm-1 {
        padding-left: .25rem !important
    }

    .p-sm-2 {
        padding: .5rem !important
    }

    .pt-sm-2,
    .py-sm-2 {
        padding-top: .5rem !important
    }

    .pr-sm-2,
    .px-sm-2 {
        padding-right: .5rem !important
    }

    .py-sm-2 {
        padding-bottom: .5rem !important
    }

    .px-sm-2 {
        padding-left: .5rem !important
    }

    .p-sm-3 {
        padding: 1rem !important
    }

    .pt-sm-3,
    .py-sm-3 {
        padding-top: 1rem !important
    }

    .pr-sm-3,
    .px-sm-3 {
        padding-right: 1rem !important
    }

    .py-sm-3 {
        padding-bottom: 1rem !important
    }

    .px-sm-3 {
        padding-left: 1rem !important
    }

    .p-sm-4 {
        padding: 1.5rem !important
    }

    .pt-sm-4,
    .py-sm-4 {
        padding-top: 1.5rem !important
    }

    .pr-sm-4,
    .px-sm-4 {
        padding-right: 1.5rem !important
    }

    .py-sm-4 {
        padding-bottom: 1.5rem !important
    }

    .px-sm-4 {
        padding-left: 1.5rem !important
    }

    .p-sm-5 {
        padding: 3rem !important
    }

    .pt-sm-5,
    .py-sm-5 {
        padding-top: 3rem !important
    }

    .pr-sm-5,
    .px-sm-5 {
        padding-right: 3rem !important
    }

    .py-sm-5 {
        padding-bottom: 3rem !important
    }

    .px-sm-5 {
        padding-left: 3rem !important
    }

    .p-sm-sm {
        padding: 2rem !important
    }

    .pt-sm-sm,
    .py-sm-sm {
        padding-top: 2rem !important
    }

    .pr-sm-sm,
    .px-sm-sm {
        padding-right: 2rem !important
    }

    .py-sm-sm {
        padding-bottom: 2rem !important
    }

    .px-sm-sm {
        padding-left: 2rem !important
    }

    .p-sm-md {
        padding: 4rem !important
    }

    .pt-sm-md,
    .py-sm-md {
        padding-top: 4rem !important
    }

    .pr-sm-md,
    .px-sm-md {
        padding-right: 4rem !important
    }

    .py-sm-md {
        padding-bottom: 4rem !important
    }

    .px-sm-md {
        padding-left: 4rem !important
    }

    .p-sm-lg {
        padding: 6rem !important
    }

    .pt-sm-lg,
    .py-sm-lg {
        padding-top: 6rem !important
    }

    .pr-sm-lg,
    .px-sm-lg {
        padding-right: 6rem !important
    }

    .py-sm-lg {
        padding-bottom: 6rem !important
    }

    .px-sm-lg {
        padding-left: 6rem !important
    }

    .mt-sm-auto {
        margin-top: auto !important
    }

    .mb-sm-auto {
        margin-bottom: auto !important
    }

    .ml-sm-auto {
        margin-left: auto !important
    }
}

@media (min-width:768px) {
    .mt-md-0 {
        margin-top: 0 !important
    }

    .mb-md-0 {
        margin-bottom: 0 !important
    }

    .ml-md-0 {
        margin-left: 0 !important
    }

    .mt-md-1 {
        margin-top: .25rem !important
    }

    .mb-md-1 {
        margin-bottom: .25rem !important
    }

    .ml-md-1 {
        margin-left: .25rem !important
    }

    .mt-md-2 {
        margin-top: .5rem !important
    }

    .mb-md-2 {
        margin-bottom: .5rem !important
    }

    .ml-md-2 {
        margin-left: .5rem !important
    }

    .mt-md-3 {
        margin-top: 1rem !important
    }

    .mb-md-3 {
        margin-bottom: 1rem !important
    }

    .ml-md-3 {
        margin-left: 1rem !important
    }

    .mt-md-4 {
        margin-top: 1.5rem !important
    }

    .mb-md-4 {
        margin-bottom: 1.5rem !important
    }

    .ml-md-4 {
        margin-left: 1.5rem !important
    }

    .mt-md-5 {
        margin-top: 3rem !important
    }

    .mb-md-5 {
        margin-bottom: 3rem !important
    }

    .ml-md-5 {
        margin-left: 3rem !important
    }

    .mt-md-sm {
        margin-top: 2rem !important
    }

    .mb-md-sm {
        margin-bottom: 2rem !important
    }

    .ml-md-sm {
        margin-left: 2rem !important
    }

    .mt-md-md {
        margin-top: 4rem !important
    }

    .mb-md-md {
        margin-bottom: 4rem !important
    }

    .ml-md-md {
        margin-left: 4rem !important
    }

    .mt-md-lg {
        margin-top: 6rem !important
    }

    .mb-md-lg {
        margin-bottom: 6rem !important
    }

    .ml-md-lg {
        margin-left: 6rem !important
    }

    .p-md-0 {
        padding: 0 !important
    }

    .pt-md-0,
    .py-md-0 {
        padding-top: 0 !important
    }

    .pr-md-0,
    .px-md-0 {
        padding-right: 0 !important
    }

    .py-md-0 {
        padding-bottom: 0 !important
    }

    .px-md-0 {
        padding-left: 0 !important
    }

    .p-md-1 {
        padding: .25rem !important
    }

    .pt-md-1,
    .py-md-1 {
        padding-top: .25rem !important
    }

    .pr-md-1,
    .px-md-1 {
        padding-right: .25rem !important
    }

    .py-md-1 {
        padding-bottom: .25rem !important
    }

    .px-md-1 {
        padding-left: .25rem !important
    }

    .p-md-2 {
        padding: .5rem !important
    }

    .pt-md-2,
    .py-md-2 {
        padding-top: .5rem !important
    }

    .pr-md-2,
    .px-md-2 {
        padding-right: .5rem !important
    }

    .py-md-2 {
        padding-bottom: .5rem !important
    }

    .px-md-2 {
        padding-left: .5rem !important
    }

    .p-md-3 {
        padding: 1rem !important
    }

    .pt-md-3,
    .py-md-3 {
        padding-top: 1rem !important
    }

    .pr-md-3,
    .px-md-3 {
        padding-right: 1rem !important
    }

    .py-md-3 {
        padding-bottom: 1rem !important
    }

    .px-md-3 {
        padding-left: 1rem !important
    }

    .p-md-4 {
        padding: 1.5rem !important
    }

    .pt-md-4,
    .py-md-4 {
        padding-top: 1.5rem !important
    }

    .pr-md-4,
    .px-md-4 {
        padding-right: 1.5rem !important
    }

    .py-md-4 {
        padding-bottom: 1.5rem !important
    }

    .px-md-4 {
        padding-left: 1.5rem !important
    }

    .p-md-5 {
        padding: 3rem !important
    }

    .pt-md-5,
    .py-md-5 {
        padding-top: 3rem !important
    }

    .pr-md-5,
    .px-md-5 {
        padding-right: 3rem !important
    }

    .py-md-5 {
        padding-bottom: 3rem !important
    }

    .px-md-5 {
        padding-left: 3rem !important
    }

    .p-md-sm {
        padding: 2rem !important
    }

    .pt-md-sm,
    .py-md-sm {
        padding-top: 2rem !important
    }

    .pr-md-sm,
    .px-md-sm {
        padding-right: 2rem !important
    }

    .py-md-sm {
        padding-bottom: 2rem !important
    }

    .px-md-sm {
        padding-left: 2rem !important
    }

    .p-md-md {
        padding: 4rem !important
    }

    .pt-md-md,
    .py-md-md {
        padding-top: 4rem !important
    }

    .pr-md-md,
    .px-md-md {
        padding-right: 4rem !important
    }

    .py-md-md {
        padding-bottom: 4rem !important
    }

    .px-md-md {
        padding-left: 4rem !important
    }

    .p-md-lg {
        padding: 6rem !important
    }

    .pt-md-lg,
    .py-md-lg {
        padding-top: 6rem !important
    }

    .pr-md-lg,
    .px-md-lg {
        padding-right: 6rem !important
    }

    .py-md-lg {
        padding-bottom: 6rem !important
    }

    .px-md-lg {
        padding-left: 6rem !important
    }

    .mt-md-auto {
        margin-top: auto !important
    }

    .mb-md-auto {
        margin-bottom: auto !important
    }

    .ml-md-auto {
        margin-left: auto !important
    }
}

@media (min-width:992px) {
    .mt-lg-0 {
        margin-top: 0 !important
    }

    .mb-lg-0 {
        margin-bottom: 0 !important
    }

    .ml-lg-0 {
        margin-left: 0 !important
    }

    .mt-lg-1 {
        margin-top: .25rem !important
    }

    .mb-lg-1 {
        margin-bottom: .25rem !important
    }

    .ml-lg-1 {
        margin-left: .25rem !important
    }

    .mt-lg-2 {
        margin-top: .5rem !important
    }

    .mb-lg-2 {
        margin-bottom: .5rem !important
    }

    .ml-lg-2 {
        margin-left: .5rem !important
    }

    .mt-lg-3 {
        margin-top: 1rem !important
    }

    .mb-lg-3 {
        margin-bottom: 1rem !important
    }

    .ml-lg-3 {
        margin-left: 1rem !important
    }

    .mt-lg-4 {
        margin-top: 1.5rem !important
    }

    .mb-lg-4 {
        margin-bottom: 1.5rem !important
    }

    .ml-lg-4 {
        margin-left: 1.5rem !important
    }

    .mt-lg-5 {
        margin-top: 3rem !important
    }

    .mb-lg-5 {
        margin-bottom: 3rem !important
    }

    .ml-lg-5 {
        margin-left: 3rem !important
    }

    .mt-lg-sm {
        margin-top: 2rem !important
    }

    .mb-lg-sm {
        margin-bottom: 2rem !important
    }

    .ml-lg-sm {
        margin-left: 2rem !important
    }

    .mt-lg-md {
        margin-top: 4rem !important
    }

    .mb-lg-md {
        margin-bottom: 4rem !important
    }

    .ml-lg-md {
        margin-left: 4rem !important
    }

    .mt-lg-lg {
        margin-top: 6rem !important
    }

    .mb-lg-lg {
        margin-bottom: 6rem !important
    }

    .ml-lg-lg {
        margin-left: 6rem !important
    }

    .p-lg-0 {
        padding: 0 !important
    }

    .pt-lg-0,
    .py-lg-0 {
        padding-top: 0 !important
    }

    .pr-lg-0,
    .px-lg-0 {
        padding-right: 0 !important
    }

    .py-lg-0 {
        padding-bottom: 0 !important
    }

    .px-lg-0 {
        padding-left: 0 !important
    }

    .p-lg-1 {
        padding: .25rem !important
    }

    .pt-lg-1,
    .py-lg-1 {
        padding-top: .25rem !important
    }

    .pr-lg-1,
    .px-lg-1 {
        padding-right: .25rem !important
    }

    .py-lg-1 {
        padding-bottom: .25rem !important
    }

    .px-lg-1 {
        padding-left: .25rem !important
    }

    .p-lg-2 {
        padding: .5rem !important
    }

    .pt-lg-2,
    .py-lg-2 {
        padding-top: .5rem !important
    }

    .pr-lg-2,
    .px-lg-2 {
        padding-right: .5rem !important
    }

    .py-lg-2 {
        padding-bottom: .5rem !important
    }

    .px-lg-2 {
        padding-left: .5rem !important
    }

    .p-lg-3 {
        padding: 1rem !important
    }

    .pt-lg-3,
    .py-lg-3 {
        padding-top: 1rem !important
    }

    .pr-lg-3,
    .px-lg-3 {
        padding-right: 1rem !important
    }

    .py-lg-3 {
        padding-bottom: 1rem !important
    }

    .px-lg-3 {
        padding-left: 1rem !important
    }

    .p-lg-4 {
        padding: 1.5rem !important
    }

    .pt-lg-4,
    .py-lg-4 {
        padding-top: 1.5rem !important
    }

    .pr-lg-4,
    .px-lg-4 {
        padding-right: 1.5rem !important
    }

    .py-lg-4 {
        padding-bottom: 1.5rem !important
    }

    .px-lg-4 {
        padding-left: 1.5rem !important
    }

    .p-lg-5 {
        padding: 3rem !important
    }

    .pt-lg-5,
    .py-lg-5 {
        padding-top: 3rem !important
    }

    .pr-lg-5,
    .px-lg-5 {
        padding-right: 3rem !important
    }

    .py-lg-5 {
        padding-bottom: 3rem !important
    }

    .px-lg-5 {
        padding-left: 3rem !important
    }

    .p-lg-sm {
        padding: 2rem !important
    }

    .pt-lg-sm,
    .py-lg-sm {
        padding-top: 2rem !important
    }

    .pr-lg-sm,
    .px-lg-sm {
        padding-right: 2rem !important
    }

    .py-lg-sm {
        padding-bottom: 2rem !important
    }

    .px-lg-sm {
        padding-left: 2rem !important
    }

    .p-lg-md {
        padding: 4rem !important
    }

    .pt-lg-md,
    .py-lg-md {
        padding-top: 4rem !important
    }

    .pr-lg-md,
    .px-lg-md {
        padding-right: 4rem !important
    }

    .py-lg-md {
        padding-bottom: 4rem !important
    }

    .px-lg-md {
        padding-left: 4rem !important
    }

    .p-lg-lg {
        padding: 6rem !important
    }

    .pt-lg-lg,
    .py-lg-lg {
        padding-top: 6rem !important
    }

    .pr-lg-lg,
    .px-lg-lg {
        padding-right: 6rem !important
    }

    .py-lg-lg {
        padding-bottom: 6rem !important
    }

    .px-lg-lg {
        padding-left: 6rem !important
    }

    .mt-lg-auto {
        margin-top: auto !important
    }

    .mb-lg-auto {
        margin-bottom: auto !important
    }

    .ml-lg-auto {
        margin-left: auto !important
    }
}

.text-justify {
    text-align: justify !important
}

.text-left {
    text-align: left !important
}

.text-right {
    text-align: right !important
}

.text-center {
    text-align: center !important
}

@media (min-width:576px) {
    .text-sm-left {
        text-align: left !important
    }

    .text-sm-right {
        text-align: right !important
    }

    .text-sm-center {
        text-align: center !important
    }
}

@media (min-width:768px) {
    .text-md-left {
        text-align: left !important
    }

    .text-md-right {
        text-align: right !important
    }

    .text-md-center {
        text-align: center !important
    }
}

@media (min-width:992px) {
    .text-lg-left {
        text-align: left !important
    }

    .text-lg-right {
        text-align: right !important
    }

    .text-lg-center {
        text-align: center !important
    }
}

.text-uppercase {
    text-transform: uppercase !important
}

.font-weight-light {
    font-weight: 300 !important
}

.font-weight-bold {
    font-weight: 600 !important
}

.text-white {
    color: #fff !important
}

.text-primary {
    color: #288cff !important
}

a.text-primary:focus,
a.text-primary:hover {
    color: #0071f4 !important
}

.text-light {
    color: #e9ecef !important
}

a.text-light:focus,
a.text-light:hover {
    color: #cbd3da !important
}

.text-dark {
    color: #343a40 !important
}

a.text-dark:focus,
a.text-dark:hover {
    color: #1d2124 !important
}

.text-muted {
    color: #adb5bd !important
}

.text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0
}

@media print {

    *,
    ::after,
    ::before {
        text-shadow: none !important;
        box-shadow: none !important
    }

    a:not(.btn) {
        text-decoration: underline
    }

    img,
    tr {
        page-break-inside: avoid
    }

    h2,
    h3,
    p {
        orphans: 3;
        widows: 3
    }

    h2,
    h3 {
        page-break-after: avoid
    }

    @page {
        size: a3
    }

    body {
        min-width: 992px !important
    }

    .container {
        min-width: 992px !important
    }

    .navbar {
        display: none
    }
}

html {
    box-sizing: border-box;
    -ms-overflow-style: scrollbar
}

body {
    color: #495057;
    background-color: #fff
}

iframe {
    border: 0
}

main {
    display: block
}

.body-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    z-index: 1040
}

.bg-blue {
    background-color: #3369d6 !important
}

a.bg-blue:focus,
a.bg-blue:hover,
button.bg-blue:focus,
button.bg-blue:hover {
    background-color: #2453b2 !important
}

.bg-white {
    background-color: #fff !important
}

a.bg-white:focus,
a.bg-white:hover,
button.bg-white:focus,
button.bg-white:hover {
    background-color: #e6e6e6 !important
}

.bg-gray {
    background-color: #6c757d !important
}

a.bg-gray:focus,
a.bg-gray:hover,
button.bg-gray:focus,
button.bg-gray:hover {
    background-color: #545b62 !important
}

.bg-gray-dark {
    background-color: #343a40 !important
}

a.bg-gray-dark:focus,
a.bg-gray-dark:hover,
button.bg-gray-dark:focus,
button.bg-gray-dark:hover {
    background-color: #1d2124 !important
}

.bg-light {
    background-color: #dee2e6 !important
}

a.bg-light:focus,
a.bg-light:hover,
button.bg-light:focus,
button.bg-light:hover {
    background-color: #c1c9d0 !important
}

.z-depth-1--hover:not(.btn):hover,
.z-depth-1-bottom--hover:not(.btn):hover,
.z-depth-1-bottom:not(.btn),
.z-depth-1-top--hover:not(.btn):hover,
.z-depth-1-top:not(.btn),
.z-depth-1:not(.btn),
.z-depth-2--hover:not(.btn):hover,
.z-depth-2-bottom--hover:not(.btn):hover,
.z-depth-2-bottom:not(.btn),
.z-depth-2-top--hover:not(.btn):hover,
.z-depth-2-top:not(.btn),
.z-depth-2:not(.btn),
.z-depth-3--hover:not(.btn):hover,
.z-depth-3-bottom--hover:not(.btn):hover,
.z-depth-3-bottom:not(.btn),
.z-depth-3-top--hover:not(.btn):hover,
.z-depth-3-top:not(.btn),
.z-depth-3:not(.btn),
.z-depth-4--hover:not(.btn):hover,
.z-depth-4-bottom--hover:not(.btn):hover,
.z-depth-4-bottom:not(.btn),
.z-depth-4-top--hover:not(.btn):hover,
.z-depth-4-top:not(.btn),
.z-depth-4:not(.btn),
.z-depth-5--hover:not(.btn):hover,
.z-depth-5-bottom--hover:not(.btn):hover,
.z-depth-5-bottom:not(.btn),
.z-depth-5-top--hover:not(.btn):hover,
.z-depth-5-top:not(.btn),
.z-depth-5:not(.btn) {
    border-color: transparent !important
}

.z-depth-0,
.z-depth-0--hover:hover {
    box-shadow: none !important
}

.z-depth-1,
.z-depth-1--hover:hover {
    box-shadow: 0 2px 5px rgba(0, 0, 0, .1), 0 2px 10px rgba(0, 0, 0, .1)
}

.z-depth-1-top,
.z-depth-1-top--hover:hover {
    box-shadow: 0 2px 10px rgba(0, 0, 0, .1)
}

.z-depth-1-bottom,
.z-depth-1-bottom--hover:hover {
    box-shadow: 0 2px 5px rgba(0, 0, 0, .1)
}

.z-depth-2,
.z-depth-2--hover:hover {
    box-shadow: 0 8px 17px rgba(0, 0, 0, .1), 0 6px 20px rgba(0, 0, 0, .1)
}

.z-depth-2-top,
.z-depth-2-top--hover:hover {
    box-shadow: 0 6px 20px rgba(0, 0, 0, .1)
}

.z-depth-2-bottom,
.z-depth-2-bottom--hover:hover {
    box-shadow: 0 8px 17px rgba(0, 0, 0, .1)
}

.z-depth-3,
.z-depth-3--hover:hover {
    box-shadow: 0 12px 15px rgba(0, 0, 0, .1), 0 17px 50px rgba(0, 0, 0, .1)
}

.z-depth-3-top,
.z-depth-3-top--hover:hover {
    box-shadow: 0 17px 50px rgba(0, 0, 0, .1)
}

.z-depth-3-bottom,
.z-depth-3-bottom--hover:hover {
    box-shadow: 0 12px 15px rgba(0, 0, 0, .1)
}

.z-depth-4,
.z-depth-4--hover:hover {
    box-shadow: 0 16px 28px rgba(0, 0, 0, .1), 0 25px 55px rgba(0, 0, 0, .1)
}

.z-depth-4-top,
.z-depth-4-top--hover:hover {
    box-shadow: 0 25px 55px rgba(0, 0, 0, .1)
}

.z-depth-4-bottom,
.z-depth-4-bottom--hover:hover {
    box-shadow: 0 16px 28px rgba(0, 0, 0, .1)
}

.z-depth-5,
.z-depth-5--hover:hover {
    box-shadow: 0 27px 24px rgba(0, 0, 0, .1), 0 40px 77px rgba(0, 0, 0, .1)
}

.z-depth-5-top,
.z-depth-5-top--hover:hover {
    box-shadow: 0 40px 77px rgba(0, 0, 0, .1)
}

.z-depth-5-bottom,
.z-depth-5-bottom--hover:hover {
    box-shadow: 0 27px 24px rgba(0, 0, 0, .1)
}

.overflow-hidden {
    overflow: hidden !important
}

.alpha-1,
.alpha-container:hover .alpha-1--hover {
    opacity: .1
}

.alpha-2,
.alpha-container:hover .alpha-2--hover {
    opacity: .2
}

.alpha-3,
.alpha-container:hover .alpha-3--hover {
    opacity: .3
}

.alpha-4,
.alpha-container:hover .alpha-4--hover {
    opacity: .4
}

.alpha-5,
.alpha-container:hover .alpha-5--hover {
    opacity: .5
}

.alpha-6,
.alpha-container:hover .alpha-6--hover {
    opacity: .6
}

.alpha-7,
.alpha-container:hover .alpha-7--hover {
    opacity: .7
}

.alpha-8,
.alpha-container:hover .alpha-8--hover {
    opacity: .8
}

.alpha-9,
.alpha-container:hover .alpha-9--hover {
    opacity: .9
}

.img-center {
    display: block;
    margin-left: auto;
    margin-right: auto
}

.top-0 {
    top: 0
}

.right-0 {
    right: 0
}

.bottom-0 {
    bottom: 0
}

.left-0 {
    left: 0
}

.top-1 {
    top: .25rem
}

.right-1 {
    right: .25rem
}

.bottom-1 {
    bottom: .25rem
}

.left-1 {
    left: .25rem
}

.top-2 {
    top: .5rem
}

.right-2 {
    right: .5rem
}

.bottom-2 {
    bottom: .5rem
}

.left-2 {
    left: .5rem
}

.top-3 {
    top: 1rem
}

.right-3 {
    right: 1rem
}

.bottom-3 {
    bottom: 1rem
}

.left-3 {
    left: 1rem
}

.top-4 {
    top: 1.5rem
}

.right-4 {
    right: 1.5rem
}

.bottom-4 {
    bottom: 1.5rem
}

.left-4 {
    left: 1.5rem
}

.top-5 {
    top: 3rem
}

.right-5 {
    right: 3rem
}

.bottom-5 {
    bottom: 3rem
}

.left-5 {
    left: 3rem
}

.top-sm {
    top: 2rem
}

.right-sm {
    right: 2rem
}

.bottom-sm {
    bottom: 2rem
}

.left-sm {
    left: 2rem
}

.top-md {
    top: 4rem
}

.right-md {
    right: 4rem
}

.bottom-md {
    bottom: 4rem
}

.left-md {
    left: 4rem
}

.top-lg {
    top: 6rem
}

.right-lg {
    right: 6rem
}

.bottom-lg {
    bottom: 6rem
}

.left-lg {
    left: 6rem
}

.cols-space>div[class*=col-]:not(:last-child) {
    margin-bottom: 4rem
}

@media (max-width:575px) {
    .cols-xs-space>div[class*=col-]:not(:last-child) {
        margin-bottom: 4rem
    }
}

@media (max-width:767px) {
    .cols-sm-space>div[class*=col-]:not(:last-child) {
        margin-bottom: 4rem
    }
}

@media (max-width:991px) {
    .cols-md-space>div[class*=col-]:not(:last-child) {
        margin-bottom: 4rem
    }
}

@media (max-width:1199px) {
    .cols-lg-space>div[class*=col-]:not(:last-child) {
        margin-bottom: 4rem
    }
}

@media (max-width:575px) {

    .row-wrapper>.row .col-12:not(:last-child),
    .row-wrapper>.row:not(:last-child) {
        margin-bottom: 3rem
    }
}

@media (min-width:576px) and (max-width:767px) {

    .row-wrapper>.row .col-sm-12:not(:last-child),
    .row-wrapper>.row:not(:last-child) {
        margin-bottom: 3rem
    }
}

@media (min-width:768px) and (max-width:991px) {

    .row-wrapper .row .col-md-12:not(:last-child),
    .row-wrapper .row:not(:last-child) {
        margin-bottom: 3rem
    }
}

@media (min-width:992px) {
    .row-wrapper>.row:not(:last-child) {
        margin-bottom: 3rem
    }
}

.font-weight-300 {
    font-weight: 300 !important
}

.font-weight-400 {
    font-weight: 400 !important
}

.font-weight-500 {
    font-weight: 500 !important
}

.font-weight-600 {
    font-weight: 600 !important
}

.font-weight-700 {
    font-weight: 700 !important
}

.font-weight-800 {
    font-weight: 800 !important
}

.font-weight-900 {
    font-weight: 900 !important
}

.lh-100 {
    line-height: 1
}

.lh-125 {
    line-height: 1.25
}

.lh-150 {
    line-height: 1.5
}

.lh-180 {
    line-height: 1.8
}

.btn i:not(:first-child),
.btn svg:not(:first-child) {
    margin-left: .5rem
}

.btn i:not(:last-child),
.btn svg:not(:last-child) {
    margin-right: .5rem
}

.btn-animated {
    position: relative;
    overflow: hidden
}

.btn-action-label .btn-label:hover {
    background: 0 0;
    color: #343a40
}

.btn-icon-label {
    position: relative
}

.btn-google-plus {
    color: #fff;
    background: #dd4b39 linear-gradient(180deg, #e26657, #dd4b39) repeat-x;
    border-color: #dd4b39;
    box-shadow: none
}

.btn-google-plus:hover {
    color: #fff;
    background: #cd3623 linear-gradient(180deg, #d55444, #cd3623) repeat-x;
    border-color: #c23321
}

.btn-google-plus:focus {
    box-shadow: none, 0 0 0 0 rgba(221, 75, 57, .5)
}

.btn-google-plus:disabled {
    color: #fff;
    background-color: #dd4b39;
    border-color: #dd4b39
}

.btn-google-plus:not(:disabled):not(.disabled).active,
.btn-google-plus:not(:disabled):not(.disabled):active,
.show>.btn-google-plus.dropdown-toggle {
    color: #fff;
    background-color: #c23321;
    background-image: none;
    border-color: #b7301f
}

.btn-google-plus:not(:disabled):not(.disabled).active:focus,
.btn-google-plus:not(:disabled):not(.disabled):active:focus,
.show>.btn-google-plus.dropdown-toggle:focus {
    box-shadow: none, 0 0 0 0 rgba(221, 75, 57, .5)
}

.btn-primary {
    color: #fff;
    background: #288cff linear-gradient(180deg, #489dff, #288cff) repeat-x;
    border-color: #288cff;
    box-shadow: none
}

.btn-primary:hover {
    color: #fff;
    background: #0278ff linear-gradient(180deg, #288cff, #0278ff) repeat-x;
    border-color: #0071f4
}

.btn-primary:focus {
    box-shadow: none, 0 0 0 0 rgba(40, 140, 255, .5)
}

.btn-primary:disabled {
    color: #fff;
    background-color: #288cff;
    border-color: #288cff
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0071f4;
    background-image: none;
    border-color: #006ce7
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: none, 0 0 0 0 rgba(40, 140, 255, .5)
}

.btn-light {
    color: #212529;
    background: #e9ecef linear-gradient(180deg, #eceff1, #e9ecef) repeat-x;
    border-color: #e9ecef;
    box-shadow: none
}

.btn-light:hover {
    color: #212529;
    background: #d3d9df linear-gradient(180deg, #dadfe4, #d3d9df) repeat-x;
    border-color: #cbd3da
}

.btn-light:focus {
    box-shadow: none, 0 0 0 0 rgba(233, 236, 239, .5)
}

.btn-light:disabled {
    color: #212529;
    background-color: #e9ecef;
    border-color: #e9ecef
}

.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active,
.show>.btn-light.dropdown-toggle {
    color: #212529;
    background-color: #cbd3da;
    background-image: none;
    border-color: #c4ccd4
}

.btn-light:not(:disabled):not(.disabled).active:focus,
.btn-light:not(:disabled):not(.disabled):active:focus,
.show>.btn-light.dropdown-toggle:focus {
    box-shadow: none, 0 0 0 0 rgba(233, 236, 239, .5)
}

.btn-dark {
    color: #fff;
    background: #343a40 linear-gradient(180deg, #52585d, #343a40) repeat-x;
    border-color: #343a40;
    box-shadow: none
}

.btn-dark:hover {
    color: #fff;
    background: #23272b linear-gradient(180deg, #44474b, #23272b) repeat-x;
    border-color: #1d2124
}

.btn-dark:focus {
    box-shadow: none, 0 0 0 0 rgba(52, 58, 64, .5)
}

.btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40
}

.btn-dark:not(:disabled):not(.disabled).active,
.btn-dark:not(:disabled):not(.disabled):active,
.show>.btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    background-image: none;
    border-color: #171a1d
}

.btn-dark:not(:disabled):not(.disabled).active:focus,
.btn-dark:not(:disabled):not(.disabled):active:focus,
.show>.btn-dark.dropdown-toggle:focus {
    box-shadow: none, 0 0 0 0 rgba(52, 58, 64, .5)
}

.card-img-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-size: cover;
    border-radius: .375rem;
    transition: all .7s cubic-bezier(.2, 1, .22, 1);
    -webkit-transition: all .7s cubic-bezier(.2, 1, .22, 1)
}

.card:hover .card-img-bg {
    transform: scale(1.1);
    -webkit-transform: scale(1.1)
}

.card-img-bg~.card-body {
    position: relative
}

.card-pricing .card-header {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem
}

.card-pricing .list-unstyled li {
    padding: .5rem 0;
    color: #6c757d
}

.card-pricing.popular {
    border: 3px solid #288cff !important
}

.close {
    transition: all .2s ease-in-out
}

.close>span:not(.sr-only) {
    background-color: rgba(0, 0, 0, .3);
    line-height: 17px;
    height: 1.25rem;
    width: 1.25rem;
    border-radius: 50%;
    font-size: .8rem;
    display: block;
    transition: all .2s ease-in-out
}

.close:focus,
.close:hover {
    color: #fff
}

.close:focus span:not(.sr-only),
.close:hover span:not(.sr-only) {
    background-color: rgba(0, 0, 0, .5)
}

.toggle-switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 1.5rem
}

[data-delimiter="1"]::before {
    content: '';
    display: block;
    width: 80%;
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -40%;
    height: 1px;
    background: radial-gradient(ellipse at center, rgba(0, 0, 0, .2) 0, rgba(255, 255, 255, 0) 75%)
}

.dropdown {
    display: inline-block
}

.dropdown-menu {
    min-width: 12rem
}

.dropdown-menu .dropdown-item {
    padding: .5rem 1rem;
    font-size: .765625rem
}

.dropdown-menu .dropdown-item>i,
.dropdown-menu .dropdown-item>svg {
    margin-right: 1rem;
    font-size: 1rem;
    vertical-align: -17%
}

.dropdown-header {
    padding-left: 1rem;
    padding-right: 1rem
}

.dropdown-menu.dropdown-menu-sm {
    min-width: 100px;
    border: .25rem
}

.dropdown-menu.dropdown-menu-lg {
    min-width: 260px;
    border-radius: .375rem
}

.footer {
    background: #fff;
    color: #6c757d
}

.footer .footer-link,
.footer .nav .nav-item .nav-link {
    color: #6c757d !important
}

.footer .footer-link:hover,
.footer .nav .nav-item .nav-link:hover {
    color: #288cff !important
}

.footer .list-unstyled li a {
    display: inline-block;
    padding: .125rem 0
}

.footer .list-unstyled li a:hover {
    color: #288cff !important
}

.footer-dark .heading {
    color: #fff
}

.icon {
    width: 3rem;
    height: 3rem
}

.icon i,
.icon svg {
    font-size: 2.25rem
}

.icon+.icon-text {
    padding-left: 1rem
}

.icon-lg {
    width: 4rem;
    height: 4rem i, svg
}

.icon-sm {
    width: 2rem;
    height: 2rem i, svg
}

.mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out
}

.navbar-nav .nav-link {
    font-size: .875rem;
    font-family: Nunito, sans-serif;
    font-weight: 500;
    text-transform: normal;
    letter-spacing: 0;
    transition: all .15s linear
}

@media (min-width:992px) {
    .navbar-nav .nav-item {
        margin-right: .5rem
    }

    .navbar-nav .nav-link {
        padding-top: .75rem;
        padding-bottom: .75rem;
        border-radius: .375rem
    }

    .navbar-nav .nav-link-icon {
        border-radius: .375rem
    }
}

.navbar-light .navbar-brand {
    color: rgba(0, 0, 0, .9)
}

.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover {
    color: rgba(0, 0, 0, .9)
}

.navbar-light .navbar-nav .nav-link {
    color: rgba(0, 0, 0, .5)
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
    color: rgba(0, 0, 0, .7)
}

.navbar-light .navbar-nav .active>.nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show>.nav-link {
    color: rgba(0, 0, 0, .9)
}

.navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, .5);
    border-color: rgba(0, 0, 0, .1)
}

.navbar-light .navbar-toggler-icon {
    background-image: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0,0,0,.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"), "#", "%23")
}

.navbar-light .navbar-text {
    color: rgba(0, 0, 0, .5)
}

.navbar-light .navbar-text a {
    color: rgba(0, 0, 0, .9)
}

.navbar-light .navbar-text a:focus,
.navbar-light .navbar-text a:hover {
    color: rgba(0, 0, 0, .9)
}

.navbar-dark .navbar-nav .nav-link {
    background-color: transparent
}

.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
    background-color: rgba(255, 255, 255, .1)
}

.navbar-dark .navbar-nav .active>.nav-link,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .show>.nav-link {
    background-color: rgba(255, 255, 255, .1)
}

@media (min-width:992px) {
    .navbar-transparent {
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 100;
        background-color: transparent !important;
        border: 0;
        box-shadow: none
    }

    .navbar-transparent.border-bottom {
        border-color: rgba(0, 0, 0, .1)
    }
}

@media (max-width:767.98px) {
    .offcanvas-collapse {
        width: 270px;
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: 1050;
        padding: 1.5rem;
        overflow-y: auto;
        transition: opacity .3s, -webkit-transform .3s;
        transition: transform .3s, opacity .3s;
        transition: transform .3s, opacity .3s, -webkit-transform .3s;
        -webkit-transform: translate3d(100%, 0, 0);
                transform: translate3d(100%, 0, 0);
        opacity: 0
    }

    .offcanvas-collapse .navbar-nav .nav-item {
        margin-bottom: .2rem
    }

    .offcanvas-collapse .navbar-nav .nav-link {
        padding-left: 1rem;
        padding-right: 1rem;
        border-radius: .375rem
    }

    .offcanvas-collapse.open {
        box-shadow: -5px 0 10px 0 rgba(0, 0, 0, .15);
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0)
    }

    .navbar-light .offcanvas-collapse {
        background-color: #fff
    }

    .navbar-dark .offcanvas-collapse {
        background-color: #343a40
    }
}

.page-item .page-link,
.page-item>span {
    margin: 0 3px;
    border-radius: .25rem;
    text-align: center
}

.main {
    position: relative
}

.slice {
    position: relative;
    padding-top: 4rem;
    padding-bottom: 4rem
}

.slice-lg {
    padding-top: 6rem;
    padding-bottom: 6rem
}

.slice-sm {
    padding-top: 2rem;
    padding-bottom: 2rem
}

.offset-top {
    padding-top: 4rem
}

.offset-top-lg {
    padding-top: 6rem
}

.offset-top-sm {
    padding-top: 2rem
}

.spotlight {
    position: relative
}

.spotlight .container {
    height: 100%
}

@media (min-height:600px) and (min-width:767px) {
    [data-spotlight=fullscreen] {
        height: 100vh
    }
}

body {
    font-family: Nunito, sans-serif;
    font-size: .875rem;
    line-height: 1.6
}

.heading {
    font-family: Nunito, sans-serif
}

.fluid-paragraph {
    width: 680px;
    margin: auto;
    padding: 0 1.5rem;
    position: relative
}

@media (max-width:767px) {
    .fluid-paragraph {
        width: 100%
    }
}
.reveal,
footer {
    position: relative
}

.uppc,
footer p {
    text-transform: uppercase !important
}

.bg-center {
    background-repeat: no-repeat;
    background-size: cover
}

.card-pricing .list-unstyled li {
    text-align: left
}

.right-text {
    text-align: right
}

footer {
    border-top: solid;
    padding-top: 2.5rem;
    padding-bottom: 1rem
}

footer p {
    text-align: center;
    font-size: 1.25rem
}

@media (max-width:767px) {

    .white-phone,
    .white-phone .h4,
    .white-phone i {
        background-color: #288cff !important;
        color: #fff
    }

    .blue-phone,
    .blue-phone p {
        color: #000 !important
    }

    .blue-phone {
        background-color: #fff !important
    }

    .blue-phone i {
        color: #288cff !important
    }

    .blue-phone .h4 {
        color: #343a40 !important
    }

    .center-phone {
        margin: auto;
        text-align: center
    }

    .hide-phone {
        display: none
    }

    .card-spacer {
        margin-bottom: 15px
    }
}

.intro {
    margin-top: 50px;
    margin-bottom: 50px
}

.button-wiggle {
    -webkit-animation: 4s 2s infinite wiggle;
            animation: 4s 2s infinite wiggle
}

@-webkit-keyframes wiggle {

    5%,
    50% {
        -webkit-transform: scale(1);
                transform: scale(1)
    }

    10% {
        -webkit-transform: scale(.9);
                transform: scale(.9)
    }

    15% {
        -webkit-transform: scale(1.15);
                transform: scale(1.15)
    }

    20% {
        -webkit-transform: scale(1.15) rotate(-5deg);
                transform: scale(1.15) rotate(-5deg)
    }

    25% {
        -webkit-transform: scale(1.15) rotate(5deg);
                transform: scale(1.15) rotate(5deg)
    }

    30% {
        -webkit-transform: scale(1.15) rotate(-3deg);
                transform: scale(1.15) rotate(-3deg)
    }

    35% {
        -webkit-transform: scale(1.15) rotate(2deg);
                transform: scale(1.15) rotate(2deg)
    }

    40% {
        -webkit-transform: scale(1.15) rotate(0);
                transform: scale(1.15) rotate(0)
    }
}

@keyframes wiggle {

    5%,
    50% {
        -webkit-transform: scale(1);
                transform: scale(1)
    }

    10% {
        -webkit-transform: scale(.9);
                transform: scale(.9)
    }

    15% {
        -webkit-transform: scale(1.15);
                transform: scale(1.15)
    }

    20% {
        -webkit-transform: scale(1.15) rotate(-5deg);
                transform: scale(1.15) rotate(-5deg)
    }

    25% {
        -webkit-transform: scale(1.15) rotate(5deg);
                transform: scale(1.15) rotate(5deg)
    }

    30% {
        -webkit-transform: scale(1.15) rotate(-3deg);
                transform: scale(1.15) rotate(-3deg)
    }

    35% {
        -webkit-transform: scale(1.15) rotate(2deg);
                transform: scale(1.15) rotate(2deg)
    }

    40% {
        -webkit-transform: scale(1.15) rotate(0);
                transform: scale(1.15) rotate(0)
    }
}

.button-pulse {
    -webkit-animation: 2s cubic-bezier(.25, 0, 0, 1) 3s infinite pulse;
            animation: 2s cubic-bezier(.25, 0, 0, 1) 3s infinite pulse;
    box-shadow: 0 0 0 0 #fff
}

.button-float {
    -webkit-animation: 1.5s linear infinite alternate float;
            animation: 1.5s linear infinite alternate float
}

@-webkit-keyframes float {
    50% {
        -webkit-transform: translateY(-px);
                transform: translateY(-px)
    }

    100% {
        -webkit-transform: translateY(-18px);
                transform: translateY(-18px)
    }
}

@keyframes float {
    50% {
        -webkit-transform: translateY(-px);
                transform: translateY(-px)
    }

    100% {
        -webkit-transform: translateY(-18px);
                transform: translateY(-18px)
    }
}

.reveal {
    opacity: 0
}

.reveal.active {
    opacity: 1
}

.active.fade-bottom {
    -webkit-animation: 1s ease-in fade-bottom;
            animation: 1s ease-in fade-bottom
}

.active.fade-left {
    -webkit-animation: 1s ease-in fade-left;
            animation: 1s ease-in fade-left
}

.active.fade-right {
    -webkit-animation: 1s ease-in fade-right;
            animation: 1s ease-in fade-right
}

@-webkit-keyframes fade-bottom {
    0% {
        -webkit-transform: translateY(50px);
                transform: translateY(50px);
        opacity: 0
    }

    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
        opacity: 1
    }
}

@keyframes fade-bottom {
    0% {
        -webkit-transform: translateY(50px);
                transform: translateY(50px);
        opacity: 0
    }

    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
        opacity: 1
    }
}

@-webkit-keyframes fade-left {
    0% {
        -webkit-transform: translateX(-100px);
                transform: translateX(-100px);
        opacity: 0
    }

    100% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
        opacity: 1
    }
}

@keyframes fade-left {
    0% {
        -webkit-transform: translateX(-100px);
                transform: translateX(-100px);
        opacity: 0
    }

    100% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
        opacity: 1
    }
}

@-webkit-keyframes fade-right {
    0% {
        -webkit-transform: translateX(100px);
                transform: translateX(100px);
        opacity: 0
    }

    100% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
        opacity: 1
    }
}

@keyframes fade-right {
    0% {
        -webkit-transform: translateX(100px);
                transform: translateX(100px);
        opacity: 0
    }

    100% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
        opacity: 1
    }
}

@media (min-width:768px) {
    .align-navbar-center {
        align-items: center !important
    }
}

@-webkit-keyframes pulse {

    0%,
    100% {
        -webkit-transform: scale(1.1);
        transform: scale(1.1)
    }

    50% {
        -webkit-transform: scale(.8);
        transform: scale(.8)
    }
}

@keyframes pulse {
    to {
        box-shadow: 0 0 0 18px rgba(255, 255, 255, 0)
    }

    0%,
    100% {
        -webkit-transform: scale(1.1);
        transform: scale(1.1)
    }

    50% {
        -webkit-transform: scale(.8);
        transform: scale(.8)
    }
}

.faa-parent.animated-hover:hover>.faa-pulse,
.faa-pulse.animated,
.faa-pulse.animated-hover:hover {
    -webkit-animation: 2s linear infinite pulse;
    animation: 2s linear infinite pulse
}

@-webkit-keyframes horizontal {

    0%,
    12%,
    24%,
    36% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0)
    }

    18%,
    30%,
    6% {
        -webkit-transform: translate(5px, 0);
        transform: translate(5px, 0)
    }
}

@keyframes horizontal {

    0%,
    12%,
    24%,
    36% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0)
    }

    18%,
    30%,
    6% {
        -webkit-transform: translate(5px, 0);
        transform: translate(5px, 0)
    }
}

.faa-horizontal.animated,
.faa-horizontal.animated-hover:hover,
.faa-parent.animated-hover:hover>.faa-horizontal {
    -webkit-animation: 2s infinite horizontal;
    animation: 2s infinite horizontal
}

p.legal {
    font-size: 20px;
}

.stats {
    margin-top: 12px;
}

div.eapps-widget {

    padding-top: 61px;

}

.card-footer:last-child {
    border-radius: 0 0 calc(0.375rem - 0.0625rem) calc(0.375rem - 0.0625rem);
}

.text-muted {
    color: #adb5bd !important;
}

.justify-content-between {
    justify-content: space-between !important;
}

.d-flex {
    display: flex !important;
}

.border-top-0 {
    border-top: 0 !important;
}

.title-articles {

    margin-bottom: 55px;

}

.shw-page-header {
    background: #000000;
    /* fallback for old browsers */
    background: radial-gradient(circle at center, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)), url('https://picsum.photos/id/0/1280/720');
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    display: flex;
    height: 500px;

    margin-bottom: 30px;
}

.shw-page-title {
    padding: 2em 0;
    margin: auto;
}

.shw-page-heading {

    margin-bottom: 30px;

}

.shw-text {

    font-size: 17px;

}

.shw-article-image {

    border: 0;
    max-width: 100%;
    vertical-align: middle;
    display: block;
    margin-left: auto;
    margin-right: auto;

}
/*All Import CSS*/
