.reveal,
footer {
    position: relative
}

.uppc,
footer p {
    text-transform: uppercase !important
}

.bg-center {
    background-repeat: no-repeat;
    background-size: cover
}

.card-pricing .list-unstyled li {
    text-align: left
}

.right-text {
    text-align: right
}

footer {
    border-top: solid;
    padding-top: 2.5rem;
    padding-bottom: 1rem
}

footer p {
    text-align: center;
    font-size: 1.25rem
}

@media (max-width:767px) {

    .white-phone,
    .white-phone .h4,
    .white-phone i {
        background-color: #288cff !important;
        color: #fff
    }

    .blue-phone,
    .blue-phone p {
        color: #000 !important
    }

    .blue-phone {
        background-color: #fff !important
    }

    .blue-phone i {
        color: #288cff !important
    }

    .blue-phone .h4 {
        color: #343a40 !important
    }

    .center-phone {
        margin: auto;
        text-align: center
    }

    .hide-phone {
        display: none
    }

    .card-spacer {
        margin-bottom: 15px
    }
}

.intro {
    margin-top: 50px;
    margin-bottom: 50px
}

.button-wiggle {
    animation: 4s 2s infinite wiggle
}

@keyframes wiggle {

    5%,
    50% {
        transform: scale(1)
    }

    10% {
        transform: scale(.9)
    }

    15% {
        transform: scale(1.15)
    }

    20% {
        transform: scale(1.15) rotate(-5deg)
    }

    25% {
        transform: scale(1.15) rotate(5deg)
    }

    30% {
        transform: scale(1.15) rotate(-3deg)
    }

    35% {
        transform: scale(1.15) rotate(2deg)
    }

    40% {
        transform: scale(1.15) rotate(0)
    }
}

.button-pulse {
    animation: 2s cubic-bezier(.25, 0, 0, 1) 3s infinite pulse;
    box-shadow: 0 0 0 0 #fff
}

.button-float {
    animation: 1.5s linear infinite alternate float
}

@keyframes float {
    50% {
        transform: translateY(-px)
    }

    100% {
        transform: translateY(-18px)
    }
}

.reveal {
    opacity: 0
}

.reveal.active {
    opacity: 1
}

.active.fade-bottom {
    animation: 1s ease-in fade-bottom
}

.active.fade-left {
    animation: 1s ease-in fade-left
}

.active.fade-right {
    animation: 1s ease-in fade-right
}

@keyframes fade-bottom {
    0% {
        transform: translateY(50px);
        opacity: 0
    }

    100% {
        transform: translateY(0);
        opacity: 1
    }
}

@keyframes fade-left {
    0% {
        transform: translateX(-100px);
        opacity: 0
    }

    100% {
        transform: translateX(0);
        opacity: 1
    }
}

@keyframes fade-right {
    0% {
        transform: translateX(100px);
        opacity: 0
    }

    100% {
        transform: translateX(0);
        opacity: 1
    }
}

@media (min-width:768px) {
    .align-navbar-center {
        align-items: center !important
    }
}

@-webkit-keyframes pulse {

    0%,
    100% {
        -webkit-transform: scale(1.1);
        transform: scale(1.1)
    }

    50% {
        -webkit-transform: scale(.8);
        transform: scale(.8)
    }
}

@keyframes pulse {
    to {
        box-shadow: 0 0 0 18px rgba(255, 255, 255, 0)
    }

    0%,
    100% {
        -webkit-transform: scale(1.1);
        -ms-transform: scale(1.1);
        transform: scale(1.1)
    }

    50% {
        -webkit-transform: scale(.8);
        -ms-transform: scale(.8);
        transform: scale(.8)
    }
}

.faa-parent.animated-hover:hover>.faa-pulse,
.faa-pulse.animated,
.faa-pulse.animated-hover:hover {
    -webkit-animation: 2s linear infinite pulse;
    animation: 2s linear infinite pulse
}

@-webkit-keyframes horizontal {

    0%,
    12%,
    24%,
    36% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0)
    }

    18%,
    30%,
    6% {
        -webkit-transform: translate(5px, 0);
        transform: translate(5px, 0)
    }
}

@keyframes horizontal {

    0%,
    12%,
    24%,
    36% {
        -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        transform: translate(0, 0)
    }

    18%,
    30%,
    6% {
        -webkit-transform: translate(5px, 0);
        -ms-transform: translate(5px, 0);
        transform: translate(5px, 0)
    }
}

.faa-horizontal.animated,
.faa-horizontal.animated-hover:hover,
.faa-parent.animated-hover:hover>.faa-horizontal {
    -webkit-animation: 2s infinite horizontal;
    animation: 2s infinite horizontal
}

p.legal {
    font-size: 20px;
}

.stats {
    margin-top: 12px;
}

div.eapps-widget {

    padding-top: 61px;

}

.card-footer:last-child {
    border-radius: 0 0 calc(0.375rem - 0.0625rem) calc(0.375rem - 0.0625rem);
}

.text-muted {
    color: #adb5bd !important;
}

.justify-content-between {
    justify-content: space-between !important;
}

.d-flex {
    display: flex !important;
}

.border-top-0 {
    border-top: 0 !important;
}

.title-articles {

    margin-bottom: 55px;

}

.shw-page-header {
    background: #000000;
    /* fallback for old browsers */
    background: radial-gradient(circle at center, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)), url('https://picsum.photos/id/0/1280/720');
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    display: flex;
    height: 500px;

    margin-bottom: 30px;
}

.shw-page-title {
    padding: 2em 0;
    margin: auto;
}

.shw-page-heading {

    margin-bottom: 30px;

}

.shw-text {

    font-size: 17px;

}

.shw-article-image {

    border: 0;
    max-width: 100%;
    vertical-align: middle;
    display: block;
    margin-left: auto;
    margin-right: auto;

}